<template>
  <div class="animated fadeIn">
    <div class="wrapper">
      <CCard>
		<CCardHeader> <h5><font-awesome-icon icon="cog"/> Settings: </h5></CCardHeader>
		<CCardBody>
			<CTabs  
			 	addNavWrapperClasses="col-sm-3 col-md-3 col-lg-3" 
                addTabsWrapperClasses="col-sm-9 col-md-9 col-lg-9" 
                fade 
                vertical 
                variant="pills">

				<CTab>
                    <template slot="title">
                        <font-awesome-icon icon="info-circle"/>
                            Appearance
                    </template>
                    <CRow>
						<CCol lg="12">
							<template>
								<div style="padding-left: 20px;">
									<h5>Themes</h5>
								</div>
							</template>
							<template>
								<div class="theme-selector">
									<div
										class="theme-card"
										@click="setActiveTheme('light', activeDocumentRoutingViews, activeFormApplicationViews)"
										:class="{ active_card: activeTheme === 'light'}"
									>
										<!-- Light theme content -->
										<center>
											<div class="light-content">
												<div>
													<CIcon name="cil-sun" size="3xl"/>
												</div>
												<div class="light-description">
													<div class="custom-radio">
														<input type="radio" id="light" name="theme" v-model="activeTheme" value="light"> &nbsp;
														<label for="light"><h5>Light</h5></label>
													</div>
												</div>
											</div>
										</center>
									</div>
									<div
										class="theme-card"
										@click="setActiveTheme('dark', activeDocumentRoutingViews, activeFormApplicationViews)"
										:class="{ active_card: activeTheme === 'dark' }"
									>
										<!-- Dark theme content -->
										<center>
											<div class="dark-content">
												<div>
													<CIcon name="cil-moon" size="3xl"/>
												</div>
												<div class="dark-description">
													<input type="radio" id="dark" name="theme" v-model="activeTheme" value="dark"> &nbsp;
													<label for="dark" class="theme-label">
														<h5>
															Dark
														</h5>
													</label>
												</div>
											</div>
										</center>
									</div>
								</div>
							</template>

							<template>
								<div style="padding-left: 20px; padding-top: 10px;">
									<h5>Document Routing Display</h5>
								</div>
							</template>

							<template>
								<div class="theme-selector">
									<div
										class="theme-card"
										:class="{active_card : activeDocumentRoutingViews === 'table'}"
										@click="setActiveTheme(activeTheme, 'table', activeFormApplicationViews)"
									>
										<!-- Table theme content -->
										<center>
											<div :class="$store.getters.getDarkMode  ? 'dark-content' : 'light-content'">
												<div>
													<CIcon name="cil-border-all" size="3xl"/>
												</div>
												<div class="light-description">
													<input type="radio" id="table" name="table" v-model="activeDocumentRoutingViews" value="table" readonly> &nbsp;
													<label for="table" class="theme-label">
														<h5 :style="{color: $store.getters.getDarkMode  ? '#fff' : '#2A2B36'}">
															Table
														</h5>
													</label>
												</div>
											</div>
										</center>
									</div>
									<div
										class="theme-card"
										:class="{active_card : activeDocumentRoutingViews === 'card'}"
										@click="setActiveTheme(activeTheme,'card', activeFormApplicationViews)"
									>
										<!-- Card theme content -->
										<center>
											<div :class="$store.getters.getDarkMode  ? 'dark-content' : 'light-content'">
												<div>
													<CIcon name="cil-browser" size="3xl"/>
												</div>
												<div class="dark-description">
													<input type="radio" id="card" name="card" v-model="activeDocumentRoutingViews" value="card" readonly> &nbsp;
													<label for="card" class="theme-label">
														<h5 :style="{color: $store.getters.getDarkMode  ? '#fff' : '#2A2B36'}">
															Card
														</h5>
													</label>
												</div>
											</div>
										</center>
									</div>
								</div>
							</template>



							<template>
								<div style="padding-left: 20px; padding-top: 10px;">
									<h5>Form Application Display</h5>
								</div>
							</template>

							<template>
								<div class="theme-selector">
									<div
										class="theme-card"
										:class="{active_card : activeFormApplicationViews === 'table'}"
										@click="setActiveTheme(activeTheme, activeDocumentRoutingViews, 'table')"
									>
										<!-- Table theme content -->
										<center>
											<div :class="$store.getters.getDarkMode  ? 'dark-content' : 'light-content'">
												<div>
													<CIcon name="cil-border-all" size="3xl"/>
												</div>
												<div class="light-description">
													<input type="radio" id="fa-table" name="fa-table" v-model="activeFormApplicationViews" value="table" readonly> &nbsp;
													<label for="fa-table" class="theme-label">
														<h5 :style="{color: $store.getters.getDarkMode  ? '#fff' : '#2A2B36'}">
															Table
														</h5>
													</label>
												</div>
											</div>
										</center>
									</div>
									<div
										class="theme-card"
										:class="{active_card : activeFormApplicationViews === 'card'}"
										@click="setActiveTheme(activeTheme, activeDocumentRoutingViews, 'card')"
									>
										<!-- Card theme content -->
										<center>
											<div :class="$store.getters.getDarkMode  ? 'dark-content' : 'light-content'">
												<div style="padding-top: 5px;">
													<font-awesome-icon icon="file-pdf" size="3x"/>
												</div>
												<div class="dark-description">
													<input type="radio" id="fa-card" name="fa-card" v-model="activeFormApplicationViews" value="card" readonly> &nbsp;
													<label for="fa-card" class="theme-label">
														<h5 :style="{color: $store.getters.getDarkMode  ? '#fff' : '#2A2B36'}">
															Card
														</h5>
													</label>
												</div>
											</div>
										</center>
									</div>
								</div>
							</template>
							
						</CCol>
					</CRow>
				</CTab>

			</CTabs>
		</CCardBody>
	  </CCard>
    </div>
  </div>
</template>

<script>
export default {
	name: "MySetting",
	data() { 
		return {
			activeTheme: null,
			myPreferenceList : [],
			activeDocumentRoutingViews : null,
			activeFormApplicationViews : null,
			isProgress : false,
		} 
	},
	mounted() {},
	created() {
		this.activeDocumentRoutingViews = this.$store.getters.getDocumentRouting ? this.$store.getters.getDocumentRouting : 'table';
		this.activeFormApplicationViews = this.$store.getters.getFormApplication ? this.$store.getters.getFormApplication : 'table';
	},
	methods: {
		async setActiveTheme(theme, views, fa_views)  {
			let user_id = this.$store.getters.getUserDetails.id;
			let isDark = theme == 'dark' ? true: false;
			if(theme != this.activeTheme || views != this.activeDocumentRoutingViews || fa_views !=this.activeFormApplicationViews) {
				if(this.isProgress) {
					return;
				}
				this.isProgress = true;
				let data = {
					"user_id" : this.paramEncoder(user_id),
					"dark_mode" : isDark,
					"document_routing" : views,
					"form_application" : fa_views
				};
				return await axios.post(`/users/toggle-user_preference`, data, {validateStatus: () => true} ).then(
				response => {
					if(response.status == 200) {
						try{
							let m = '';
							if(theme != this.activeTheme && views == this.activeDocumentRoutingViews && fa_views==this.activeFormApplicationViews) {
								m = 'Theme mode'
							}else if (views != this.activeDocumentRoutingViews && theme == this.activeTheme && fa_views == this.activeFormApplicationViews){
								m = 'Document Routing Display'
							} else if(this.activeFormApplicationViews != fa_views && this.activeTheme == theme && views == this.activeDocumentRoutingViews) {
								m='Form Application Display'
							}
							
							if (theme === 'dark') {
							// Commit the dark mode state
								if(this.activeTheme!=theme) {
									this.$store.commit('toggle', 'dm');
								}
							} else {
								// Set the dark mode state to false
								this.$store.commit('setDarkMode', false);
							}
							this.activeTheme = this.activeTheme == theme ? this.activeTheme: theme;
							this.activeDocumentRoutingViews = views;
							this.activeFormApplicationViews = fa_views;
							this.$store.commit('documentRoutingViews', views);
							this.$store.commit('formApplicationViews', fa_views);
							document.body.classList.remove('theme-dark', 'theme-dark-popover'); // Remove previous theme classes
							if(theme == 'dark') {
								document.body.classList.add(`theme-dark-popover`);
								document.body.classList.add(`theme-dark`);
							}

							this.$swal({ 
								toast: true,
								position: 'top-right',
								showConfirmButton: false,
								timer: 1500,
								icon: 'success',
								title: `${m} changed successfully!`,
								showConfirmButton: false,
								timerProgressBar: true,
							})
							this.isProgress = false;
							
						}finally{
							this.isProgress = false;
						}
					}
				}
			)
			}						 

		},
	},
	watch: {
		'$store.state.dm' : {
			immediate: true,
			handler(value) {
				this.activeTheme = value ? 'dark' : 'light';
			}
		}
	},
};
</script>

<style scoped>
input[type="radio"], input[type="checkbox"], label {
    pointer-events: none;
}

.theme-dark .active_card{
	border: 1px solid white;
}

.active_card{
	border: 1px solid #181924;
}
</style>